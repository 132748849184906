<template>
  <div>issues result
<input type="file" name="image" accept="image/*" capture="environment">
  </div>
</template>
<script>
export default {

}
</script>

